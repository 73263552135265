import { useMemo, useRef, useState } from 'react';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import CustomDataGrid from '../../components/CustomDataGrid/CustomDataGrid';
import CustomTextField from '../../components/CustomTextField/CustomTextField';

import { getUsers } from '../../api/user';
import { USERS_TABLE_COLUMNS } from '../../const/usersTableColumns';
import { useQuery } from 'react-query';
import { BaseFilters } from '../../types/table';
import { BASE_PAGE_SIZE_OPTIONS, calculateRowHeightAndPageSize } from '../../helpers/datagrid';

const UsersView = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const rowProperties = calculateRowHeightAndPageSize(210);
    const savedPageSize = localStorage.getItem('users.pageSize');

    const [filters, setFilters] = useState<BaseFilters>({
        search: '',
        pageSize:
            savedPageSize &&
            +savedPageSize &&
            BASE_PAGE_SIZE_OPTIONS.concat(rowProperties.pageSize).includes(+savedPageSize)
                ? +savedPageSize
                : rowProperties.pageSize,
        page: 1,
    });

    const { data: usersData, isLoading } = useQuery(['users', filters], () => getUsers(filters));

    const users = usersData?.users || [];

    const rowCountRef = useRef(usersData?.totalResults || 0);

    const rowCount = useMemo(() => {
        if (usersData?.totalResults !== undefined) {
            rowCountRef.current = usersData.totalResults;
        }
        return rowCountRef.current;
    }, [usersData?.totalResults]);

    return (
        <Box component="div" flexDirection="column">
            <Grid
                item
                xs={12}
                sx={{
                    padding: '24px 20px',
                }}>
                <Grid container gap={isSmallScreen ? '16px' : 0}>
                    <Grid item xs={isSmallScreen ? 12 : 4}>
                        <CustomTextField
                            fullWidth
                            name="search"
                            label="Search"
                            size="small"
                            onChange={(e: any) =>
                                setFilters({ ...filters, search: e.target.value, page: 1 })
                            }
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                container
                gap="16px"
                sx={{
                    padding: '16px 20px',
                }}>
                <Grid item xs={12}>
                    <CustomDataGrid
                        tableName="users"
                        rowProperties={rowProperties}
                        height="calc(100vh - 204px)"
                        disableColumnFilter
                        rows={users || []}
                        filters={filters}
                        onFiltersChange={(filters) => setFilters(filters)}
                        onCellClick={(params) => {
                            if (params.field !== '__check__') {
                                navigate('/users/' + params.row.id);
                            }
                        }}
                        columns={USERS_TABLE_COLUMNS({
                            theme: theme,
                            onEdit: (row) => navigate('/users/' + row.id),
                        })}
                        rowCount={rowCount}
                        loading={isLoading}
                        localeText={{
                            noRowsLabel: 'No users found.',
                            noResultsOverlayLabel: 'No users found.',
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default UsersView;
