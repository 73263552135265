import { useMemo, useRef, useState } from 'react';
import { Box, Button, Grid, Tab, Tabs, Typography, useMediaQuery, useTheme } from '@mui/material';

import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import CustomDataGrid from '../../components/CustomDataGrid/CustomDataGrid';
import CustomTextField from '../../components/CustomTextField/CustomTextField';
import CustomDialog from '../../components/CustomDialog/CustomDialog';
import { useSetAtom } from 'jotai';
import { toastAtom } from '../../atoms/toastAtom';
import { Collection, CollectionFilters } from '../../types/collection';
import {
    archiveCollection,
    deleteCollection,
    getCollections,
    restoreCollection,
} from '../../api/collection';
import { COLLECTIONS_TABLE_COLUMNS } from '../../const/collectionsTableColumns';
import CollectionCreator from './CollectionCreator';
import { BaseFilters } from '../../types/table';
import { BASE_PAGE_SIZE_OPTIONS, calculateRowHeightAndPageSize } from '../../helpers/datagrid';
import { APIError } from '../../axios/axiosVeerlInstance';
import { ModelTabs } from '../Models/ModelsView';

const CollectionsView = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const theme = useTheme();
    const location = useLocation();
    const setToastState = useSetAtom(toastAtom);

    const [searchParams] = useSearchParams();
    const queryTab = searchParams.get('tab');

    const rowProperties = calculateRowHeightAndPageSize(210);
    const savedPageSize = localStorage.getItem('collections.pageSize');

    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const { collectionsFilters } = location.state || {};
    const { referrer } = location.state || [];

    const [filters, setFilters] = useState<CollectionFilters>(
        collectionsFilters || {
            search: '',
            sort: {
                field: 'dateUpdate',
                order: 'desc',
            },
            pageSize:
                savedPageSize &&
                +savedPageSize &&
                BASE_PAGE_SIZE_OPTIONS.concat(rowProperties.pageSize).includes(+savedPageSize)
                    ? +savedPageSize
                    : rowProperties.pageSize,
            archived: queryTab === 'archived' ? 'true' : 'false',
            page: 1,
        }
    );

    const [isCreatorOpen, setIsCreatorOpen] = useState<boolean>(false);
    const [collectionToDelete, setCollectionToDelete] = useState<Collection | null>(null);
    const [editingCollection, setEditingCollection] = useState<Collection | null>(null);

    const [currentTab, setCurrentTab] = useState<ModelTabs>((queryTab as ModelTabs) || 'active');

    const [collectionToArchive, setCollectionToArchive] = useState<Collection | null>(null);

    const { data: collectionsResponse, isFetching } = useQuery(['collections', filters], () =>
        getCollections(filters)
    );

    const collections = collectionsResponse?.collections || [];
    const newReferrer = referrer?.length ? [...referrer, '/collections'] : ['/collections'];

    const deleteMutation = useMutation(deleteCollection, {
        onSuccess: () => {
            setToastState({
                message: 'Collection deleted successfully!',
                severity: 'success',
            });
            setCollectionToDelete(null);
            queryClient.invalidateQueries('collections');
        },
        onError: (error: APIError) => {
            setToastState({
                message: error?.message,
                severity: 'error',
            });
        },
    });

    const archiveMutation = useMutation(archiveCollection, {
        onSuccess: () => {
            setToastState({
                message: 'Collection archived successfully!',
                severity: 'success',
            });
            setCollectionToArchive(null);
            queryClient.invalidateQueries('collections');
        },
        onError: (error: APIError) => {
            setToastState({
                message: error?.message,
                severity: 'error',
            });
        },
    });

    const restoreMutation = useMutation(restoreCollection, {
        onSuccess: () => {
            setToastState({
                message: 'Collection restored successfully!',
                severity: 'success',
            });
            setCollectionToArchive(null);
            queryClient.invalidateQueries('collections');
        },
        onError: (error: APIError) => {
            setToastState({
                message: error?.message,
                severity: 'error',
            });
        },
    });

    const onDeleteCollection = () => {
        if (!collectionToDelete) return;

        deleteMutation.mutate(collectionToDelete.id);
    };

    const onArchiveModel = () => {
        if (!collectionToArchive) return;

        if (!collectionToArchive?.archived) {
            archiveMutation.mutate(collectionToArchive.id);
        } else {
            restoreMutation.mutate(collectionToArchive.id);
        }
    };

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: 'active' | 'archived') => {
        setCurrentTab(newValue);
        setFilters({
            ...filters,
            page: 1,
            archived: newValue === 'archived' ? 'true' : 'false',
        });

        navigate(`?tab=${newValue}`, {
            state: {
                referrer: referrer,
                collectionsFilters,
                modelsFilters: filters,
            },
        });
    };

    const onCollectionCreatorClose = () => {
        setIsCreatorOpen(false);
        setEditingCollection(null);
    };

    const rowCountRef = useRef(collectionsResponse?.totalResults || 0);

    const rowCount = useMemo(() => {
        if (collectionsResponse?.totalResults !== undefined) {
            rowCountRef.current = collectionsResponse.totalResults;
        }
        return rowCountRef.current;
    }, [collectionsResponse?.totalResults]);

    return (
        <>
            <CollectionCreator
                editingCollection={editingCollection}
                isOpen={isCreatorOpen || editingCollection !== null}
                onClose={onCollectionCreatorClose}
            />
            <Box component="div" flexDirection="column">
                <Grid
                    item
                    xs={12}
                    sx={{
                        padding: '24px 20px',
                    }}>
                    <Grid container gap={isSmallScreen ? '16px' : 0}>
                        <Grid item xs={isSmallScreen ? 12 : 4}>
                            <CustomTextField
                                fullWidth
                                name="search"
                                label="Search"
                                size="small"
                                onChange={(e: any) =>
                                    setFilters({
                                        ...filters,
                                        search: e.target.value,
                                        page: 1,
                                    })
                                }
                            />
                        </Grid>
                        <Grid item xs={isSmallScreen ? 12 : 8}>
                            <Box
                                component="div"
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}>
                                <Button
                                    onClick={() => setIsCreatorOpen(true)}
                                    variant="contained"
                                    color="primary"
                                    endIcon={!isMediumScreen ? <PlusIcon /> : null}>
                                    {!isMediumScreen ? 'Add Collection' : <PlusIcon />}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    gap="16px"
                    sx={{
                        padding: '16px 20px',
                    }}>
                    <Grid item xs={12}>
                        <Tabs value={currentTab} onChange={handleTabChange}>
                            <Tab label="Active" value="active" />
                            <Tab label="Archived" value="archived" />
                        </Tabs>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            position: 'relative',
                        }}>
                        <CustomDataGrid
                            height="calc(100vh - 268px)"
                            disableColumnFilter
                            tableName="collections"
                            rows={collections}
                            onCellClick={(params) => {
                                if (params.field !== '__check__') {
                                    navigate('/models', {
                                        state: {
                                            collection: params.row,
                                            collectionsFilters: filters,
                                            referrer: newReferrer,
                                        },
                                    })
                                }
                            }}
                            columns={COLLECTIONS_TABLE_COLUMNS({
                                currentTab,
                                onEdit: (row) => setEditingCollection(row),
                                onDelete: (row) => setCollectionToDelete(row),
                                onArchive: (row) => setCollectionToArchive(row),
                                onOpenModels: (row) =>
                                    navigate('/models', {
                                        state: {
                                            collection: row,
                                            collectionsFilters: filters,
                                            referrer: newReferrer,
                                        },
                                    }),
                            })}
                            rowCount={rowCount}
                            rowProperties={rowProperties}
                            filters={filters}
                            onFiltersChange={(filters) => {
                                setFilters({
                                    ...filters,
                                    sort: {
                                        field: filters.sort ? filters.sort.field : 'dateUpdate',
                                        order: filters.sort ? filters.sort.order : 'desc',
                                    },
                                } as CollectionFilters);
                            }}
                            loading={isFetching}
                            localeText={{
                                noRowsLabel: isFetching
                                    ? ''
                                    : filters.search !== '' || currentTab === 'archived'
                                      ? 'No collections found.'
                                      : 'Create your first collection.',
                                noResultsOverlayLabel: isFetching
                                    ? ''
                                    : filters.search !== '' || currentTab === 'archived'
                                      ? 'No collections found.'
                                      : 'Create your first collection.',
                            }}
                        />
                    </Grid>
                </Grid>
                <CustomDialog
                    open={!!collectionToArchive}
                    onClose={() => setCollectionToArchive(null)}
                    onConfirm={onArchiveModel}
                    title={currentTab === 'active' ? 'Archive collection' : 'Restore collection'}
                    content={
                        currentTab === 'active' ? (
                            <Typography variant="p1" textAlign="center">
                                Are you sure that you want to archive collection{' '}
                                <Typography variant="p1SemiBold">
                                    {collectionToArchive?.name}
                                </Typography>
                                ? From this action collection won’t be available in marketplace
                                anymore.
                            </Typography>
                        ) : (
                            <Typography variant="p1" textAlign="center">
                                Are you sure that you want to restore collection{' '}
                                <Typography variant="p1SemiBold">
                                    {collectionToArchive?.name}
                                </Typography>
                                ? From this action collection will be available in marketplace
                                again.
                            </Typography>
                        )
                    }
                />
                <CustomDialog
                    open={!!collectionToDelete}
                    onClose={() => setCollectionToDelete(null)}
                    onConfirm={onDeleteCollection}
                    title="Delete collection"
                    content={
                        <Typography variant="p1" textAlign="center">
                            Are you sure that you want to delete collection{' '}
                            <Typography variant="p1SemiBold">{collectionToDelete?.name}</Typography>
                            ? From this action collection will be permanently deleted.
                        </Typography>
                    }
                />
            </Box>
        </>
    );
};

export default CollectionsView;
